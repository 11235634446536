<template>
  <div class="login">
    <div style="text-align: center; width: 290px">
      <el-card style="height: 200px; padding-top: 20px">
        <el-input v-model="loginInfo.account" placeholder="账号">
          <template #prepend>
            <el-button :icon="User" />
          </template>
        </el-input>
        <el-input
          v-model="loginInfo.password"
          style="margin: 20px 0"
          placeholder="密码"
          type="password"
        >
          <template #prepend>
            <el-button :icon="Lock" />
          </template>
        </el-input>
        <el-button v-debounce type="primary" style="width: 100%" @click="login"
          >登录</el-button
        >
      </el-card>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { imitate_login } from "@/api/public/index.js";
import { useCache } from "@/hooks/useCache.js";
import { useMenu } from "@/store/menu.js";
import { useRouter } from "vue-router";
import { User, Lock } from "@element-plus/icons-vue";
import md5 from "js-md5";

const { wsCache } = useCache();
const router = useRouter();

const loginInfo = reactive({
  account: "",
  password: "",
});

if (process.env.NODE_ENV == "development") {
  loginInfo.account = "91442000MACFBFJ558";
  loginInfo.password = "123456";
}

// 登录
const login = () => {
  imitate_login({
    ebc_code: loginInfo.account,
    password: md5(md5(loginInfo.password)),
  }).then((data) => {
    if (data.code == 200) {
      // wsCache.set("Token", data.data.token);
      wsCache.set("Token", "import");
      const menu = useMenu();
      const waitInit = menu.setImportMenu();
      if (waitInit) {
        router.push("/import");
      }
    }
  });
};
</script>

<style scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login_box {
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
